<i18n src="@/locales/montenegro_dental_center.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-home-content-clinic.json" />

<template>
  <div class="clinic-montenegro">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        {{
          $t('article_p1')
        }}
      </p>
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{
            $t('article_p2')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          <span v-html="$t('article_p3')" />
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div
          v-for="cardContent in cardContentClinic"
          :key="cardContent.id"
          class="column is-2"
        >
          <ImanCountryCard
            :content="cardContent"
            :country-card-class="countryOverlayClass"
          />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import ImanCountryCard from "@/components/ImanCountryCard";
  import {cardHomeContentClinicMixin} from "@/mixins/card-home-content-clinic-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";
  export default {
    name: 'MontenegroDentalCenter',
    components: {
      ImanMoreInfo,
      ImanArticleSection,
      ImanCountryCard
    },
    mixins: [
      metaMixin,
      articlesCardContentFilterMixin,
      cardHomeContentClinicMixin
    ],
    data() {
      return {
        articlesCardContentId: [22, 62, 53, 52],
        mainBackgroundClass: 'none-background',
        countryOverlayClass: {
          overlay:'overlay show-overlay',
          element: 'element show-element'
        },
        img: [
          {
            path: 'clinic/swiss/prof-ihde-anveli-dental.jpg',
            position: 'right'
          },
          {
            path: 'clinic/montenegro/clinique-dentaire-montenegro.jpg',
            position: 'right'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/7MZewXtD0FQ'
        },

      }
    },
    computed: {
      cardContentClinic () {
        return this.cardHomeContentClinic.filter((cardClinic) => cardClinic.id !== 2)
      },
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_4.4.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 970 || document.documentElement.scrollTop > 970) {
          this.mainBackgroundClass = 'fixed-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.countryOverlayClass = {
            overlay:'overlay show-overlay',
            element: 'element show-element'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/clinic/montenegro/H1-clinique-dentaire-montenegro-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/clinic/montenegro/H1-clinique-dentaire-montenegro-d.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      /* smartphone en mode portait */
      background-image: url("../../../assets/img/clinic/montenegro/H1-clinique-dentaire-montenegro-m.jpg");
    }
  }
</style>
