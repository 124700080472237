import { render, staticRenderFns } from "./MontenegroDentalCenter.vue?vue&type=template&id=dfdc759c&scoped=true"
import script from "./MontenegroDentalCenter.vue?vue&type=script&lang=js"
export * from "./MontenegroDentalCenter.vue?vue&type=script&lang=js"
import style0 from "./MontenegroDentalCenter.vue?vue&type=style&index=0&id=dfdc759c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfdc759c",
  null
  
)

/* custom blocks */
import block0 from "@/locales/montenegro_dental_center.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fclinic%2Fmontenegro-dental-center%2FMontenegroDentalCenter.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fclinic%2Fmontenegro-dental-center%2FMontenegroDentalCenter.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/card-home-content-clinic.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fclinic%2Fmontenegro-dental-center%2FMontenegroDentalCenter.vue&external"
if (typeof block2 === 'function') block2(component)

export default component.exports